import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Circle } from "rc-progress"

const PercentCircle = ({ percent = 0, total, totalCities }) => {
  const [ percentage, updatePercentage ] = useState(0)
  useEffect(() => updatePercentage(percent), [percent])
  return (
    <Wrapper className={totalCities ? "has-hover" : ""}>
      <div style={{transform: "scaleX(-1)"}}>
        <Circle
          percent={percentage}
          strokeWidth={7}
          strokeColor={"rgba(255,255,255,1)"}
          trailWidth={6.5}
          trailColor={"rgba(224, 237, 239,0.4)"}
        />
      </div>
      <div className="inner main">
        <div className="content main">
          <span className="number">{percent}%</span>
          {total && <span className="text">{total} cities</span>}
        </div>
      </div>
      {totalCities && total && (
        <div className="inner hover">
          <div className="content">
            <span className="number">{total}</span>
            <span className="text">out of {totalCities} cities</span>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 178px;
  color: white;
  font-family: ${({ theme }) => theme.fonts['roboto']};
  line-height: 1;

  svg{
    width: 100%;
    height: auto;

    .rc-progress-circle-path {
      stroke-linecap: butt;
      transition: stroke-dashoffset 1s ease-in-out, stroke-dasharray 1s ease-in-out !important;
     
    }
  }

  .inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .number {
    display: block;
    font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    user-select: none;
  }

  .text {
    display: block;
    font-weight: 500;
    font-size: 1rem;
    user-select: none;
  }

  .inner.hover {
    opacity: 0;
  }

  &.has-hover {

    .inner {
      transition: opacity 0.15s ease;
    }

    &:hover {
      .inner.main {
        opacity: 0;
      }
      .inner.hover {
        opacity: 1;
      }
    }
  }
`

export default PercentCircle
